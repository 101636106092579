// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}

import '../css/app.css';
let $ = window.$;

$(".sort-by-select").on('change', function(e) {
  $('#sort-by-form').submit();
});

$(".checkbox-auto-submit").change(function() {
    $(".checkbox-auto-submit-form").submit()

});
